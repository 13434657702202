import React from "react"

export default props => (
  <li className="flex items-center">
    <div className="w-9/12 overflow-x-hidden">
      <h4 className="text-blue-500 font-bold truncate">{props.repo.name}</h4>
      <p
        className="text-sm text-black overflow-y-hidden"
        style={{ height: "75px" }}
      >
        {props.repo.description};
      </p>
    </div>
    <div className="flex-1 text-center">
      <a href={props.repo.html_url} target="_blank" rel="noopener noreferrer">
        View
      </a>
    </div>
  </li>
)
