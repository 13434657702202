import React, { useEffect, useState } from "react"
// import repos from "../data/repos";
import Repo from "./repo"

export default () => {
  const [repos, setRepos] = useState([])
  const [reposCount, setReposCount] = useState([])

  useEffect(() => {
    const data = sessionStorage.getItem("repos")
    let myRepos

    if (data) {
      myRepos = JSON.parse(data)
      setReposCount(myRepos.length)
      myRepos = myRepos.slice(1, 7)
      return setRepos(myRepos)
    }

    async function fetchRepos() {
      const response = await fetch(
        "https://api.github.com/users/shankyjs/repos"
      )
      myRepos = await response.json()
      setReposCount(myRepos.length)
      sessionStorage.setItem("repos", JSON.stringify(myRepos))
      myRepos = myRepos.slice(1, 7)
      setRepos(myRepos)
    }

    fetchRepos()
  }, [])

  return (
    <div className="max-w-4xl mx-auto mt-12">
      <header className="text-center">
        <h2 className="text-3xl font-bold"> My work on Open Source </h2>
        <p>Collaboration and code contribution</p>
      </header>
      <ul className="repos-list mt-8 ml-4 mr-4">
        {repos.map(repo => {
          return <Repo repo={repo} key={repo.id} />
        })}
      </ul>
      <div className="mt-8 text-center">
        <a
          href="https://github.com/shankyjs"
          className="btn"
          target="_blank"
          rel="noopener noreferrer"
        >
          Watch more on Github ({reposCount})
        </a>
      </div>
    </div>
  )
}
