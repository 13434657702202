import React from "react"

export default props => {
  const course = props.element
  return (
    <div className="flex-1 mb-5 mr-5 justify-center items-center">
      <div className="shadow p-4 bg-white mr-4 rounded mb-10">
        <h4 className="font-bold">
          <a href={course.url} target="_blank" rel="noopener noreferrer">
            {course.title}
          </a>
        </h4>
        <div className="text-center">
          <span className="inline-block bg-blue-100 text-blue-500 p-2 mt-2 radius">
            Progress : {parseInt(course.progress)}%
          </span>
        </div>
      </div>
    </div>
  )
}
