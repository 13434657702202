import React from "react"
import ilustration from "../imgs/ilustration.svg"

export default () => (
  <header className="bg-gray-300">
    <div className="container mx-auto p-12 max-w-4xl">
      <div className="container flex">
        <div className="flex-1 justify-center">
          <h1 className="font-bold text-blue-500 text-6xl flex text-center">
            Hello! I'm Shanky
          </h1>
          <p className="text-xl font-normal text-black">
            A self-taught person experienced on <strong className="font-bold">improving</strong> the <strong className="font-bold">Software
            Development</strong> process.
          </p>
          <p className="text-xl font-light">
            I'm a DevOps in a <strong className="font-bold"> hard </strong>
            relationship with JavaScript
          </p>
        </div>
        <div className="flex justify-center">
          <img
            className="imageL"
            src={ilustration}
            alt="Hombre en su laptop"
            style={{ height: "300px" }}
          />
        </div>
      </div>
    </div>
    <div className="flex justify-center pb-10">
      <button className="btn">
        <a
          href="https://linktr.ee/shankyjs_"
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact me :)
        </a>
      </button>
    </div>
  </header>
)
