import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Posts from './posts';
import Certificate from './certificate'
import Course from './course'

export default () => {
  const data = useStaticQuery(graphql`
    {
      codigofacilitoJson {
        data {
          certificates {
            code
            score
            title
          }
          courses {
            title
            progress
            url
          }
        }
      }
    }
  `)

  console.log(data)

  return (
    <section>
      <div className="mt-10 flex">
        <div className="container max-w-4xl mx-auto flex-wrap">
          <Posts 
          data={data.codigofacilitoJson.data.certificates}
          card={Certificate}
          title="Dev Courses" />

          <Posts 
          data={data.codigofacilitoJson.data.courses}
          card={Course}
          title="Ops Courses" />

        </div>
      </div>
    </section>
  )
}
