import React from "react"

export default props => {
  const certificate = props.element
  return (
    <div className="flex-1 mb-5 m-2 justify-center items-center">
      <div className="shadow p-5 bg-white rounded mr-4 flex-shrink-0">
        <h4 className="font-bold">{certificate.title}</h4>
        <div className="text-center">
          <span className="inline-block bg-blue-100 text-blue-500 p-2 mt-2 radius">
            Progress : {certificate.score}%
          </span>
        </div>
      </div>
    </div>
  )
}
