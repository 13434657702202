import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

export default props => {
  const data = useStaticQuery(graphql`
    {
      allEducationJson {
        edges {
          node {
            slug
            title
            description
          }
        }
      }
    }
  `)

  return (
    <div className="container max-w-4xl mx-auto mt-20">
      <h2 className="text-3xl font-bold text-center">
        Education History
      </h2>
      <nav className="container flex justify-center mt-8">
        {data.allEducationJson.edges.map((element, index) => {
          const { node } = element
          return (
            <article className="flex-1 bg-white shadow m-4 max-w-sm p-4">
              <header>
                <p className="font-bold leading-loose text-center"> {node.title} </p>
              </header>
              <div className="mt-8">
                <p className="font-light text-center">{node.description}</p>
                <Link to={`/${node.slug}`} className="btn inline-block mt-4 flex justify-center">
                  Go
                </Link>
              </div>
            </article>
          )
        })}
      </nav>
    </div>
  )
}
